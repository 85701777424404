<template>
  <div></div>
</template>
<script>
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
const card = stripe.elements().create("card", {
  hidePostalCode: true,
});

export default {
  name: "StripeElement",
  mounted() {
    card.mount(this.$el);
    card.addEventListener("change", this.errorHandler);
  },
  beforeDestroy() {
    card.removeEventListener("change", this.errorHandler);
  },
  methods: {
    createToken() {
      return stripe.createToken(card);
    },
    errorHandler(event) {
      if (event.error) {
        this.$emit("error", event.error.message);
      } else {
        this.$emit("error", "");
      }
    },
  },
};
</script>
