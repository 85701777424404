<template>
  <div class="flex-x align-center justify-center q-my-xl">
    <div
      class="card bg-white border-radius6 overflow-hidden"
      :style="{ width: '90%' }"
    >
      <div class="v-card__text pa-0">
        <div class="flex-x">
          <div
            class="pa-5"
            style="min-width: 450px; max-width: 450px;"
            v-if="isAdmin"
          >
            <form v-on:submit.prevent="$v.$invalid ? null : submit()" class>
              <span class="an-bold-35">Billing &</span>
              <br />
              <span class="an-bold-35 primary--text">Payment info</span>
              <div
                class="divider section-spacer my-3"
                style="width:66px;height:10px;"
              ></div>
              <span class="label-span">Name on Card*</span>
              <div class="row">
                <q-input
                  v-model="nameoncard"
                  @input="$v.nameoncard.$touch()"
                  @blur="$v.nameoncard.$touch()"
                  outlined
                  placeholder="Name on Card"
                  type="text"
                  class="q-mb-sm "
                  bottom-slots
                  :error="fieldErrors('nameoncard').length > 0"
                  no-error-icon
                  input-class="caps-input"
                  bg-color="white"
                >
                  <template v-slot:error>
                    <div class="text-pink">
                      {{
                        fieldErrors("nameoncard").length > 0
                          ? fieldErrors("nameoncard")[0]
                          : ""
                      }}
                    </div>
                  </template>
                </q-input>
              </div>
              <p v-show="cardErrors" class="mt-4 pink--text">
                {{ cardErrors }}
              </p>
              <!-- NEW -->
              <stripe-element @error="stripeErrorHandler" ref="card" />

              <span class="label-span mt-4">Coupon Code</span>
              <div class="row">
                <q-input
                  v-model="couponcode"
                  @keyup.delete="couponCodeDeleteEventHandler"
                  outlined
                  autofocus
                  type="text"
                  class="q-mb-lg"
                  input-class="caps-input"
                  :class="[
                    couponcodeMessage !== null ? 'no-details-input' : '',
                  ]"
                />
                <q-btn
                  :icon="$icons.matCheck"
                  @click="checkCouponCode"
                  class="q-ma-none border-radius-ltb-0"
                  dense
                  size="xl"
                  round
                  flat
                  :style="{ color: couponBtnColor }"
                  style="text-decoration: 'underline'"
                />
              </div>
              <p
                v-if="couponcodeMessage"
                class="mt-1"
                :class="[
                  isValidCouponcode === true ? 'active--text' : 'pink--text',
                ]"
              >
                {{ couponcodeMessage }}
              </p>
              <span class="label-span">Billing Info:</span>
              <div class="row">
                <q-input
                  v-model="addressForm.street_one"
                  @input="$v.addressForm.street_one.$touch()"
                  @blur="$v.addressForm.street_one.$touch()"
                  outlined
                  placeholder="Address line 1"
                  type="text"
                  class="q-mb-sm"
                  bottom-slots
                  :error="fieldErrors('addressForm.street_one').length > 0"
                  no-error-icon
                  input-class="caps-input"
                  bg-color="white"
                >
                  <template v-slot:error>
                    <div class="text-pink">
                      {{
                        fieldErrors("addressForm.street_one").length > 0
                          ? fieldErrors("addressForm.street_one")[0]
                          : ""
                      }}
                    </div>
                  </template>
                </q-input>
              </div>
              <div class="row">
                <q-input
                  v-model="addressForm.street_two"
                  outlined
                  placeholder="Address line 2"
                  class="q-mb-lg"
                  type="text"
                  bg-color="white"
                />
              </div>
              <div class="row">
                <div class="infocardhalfflexdiv-right pr-2">
                  <q-input
                    v-model="city"
                    @input="$v.city.$touch()"
                    @blur="$v.city.$touch()"
                    outlined
                    placeholder="City"
                    type="text"
                    class="q-mb-lg"
                    bottom-slots
                    :error="fieldErrors('city').length > 0"
                    no-error-icon
                    bg-color="white"
                  >
                    <template v-slot:error>
                      <div class="text-pink">
                        {{
                          fieldErrors("city").length > 0
                            ? fieldErrors("city")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                </div>
                <div class="infocardhalfflexdiv">
                  <q-input
                    v-model="zipcode"
                    @input="$v.zipcode.$touch()"
                    @blur="$v.zipcode.$touch()"
                    outlined
                    placeholder="Zip/Postal"
                    type="text"
                    class="q-mb-lg q-ml-sm"
                    bottom-slots
                    :error="fieldErrors('zipcode').length > 0"
                    no-error-icon
                    input-class="caps-input"
                    bg-color="white"
                  >
                    <template v-slot:error>
                      <div class="text-pink">
                        {{
                          fieldErrors("zipcode").length > 0
                            ? fieldErrors("zipcode")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="row">
                <div class="infocardhalfflexdiv-right">
                  <q-input
                    v-model="state"
                    @input="$v.state.$touch()"
                    @blur="$v.state.$touch()"
                    outlined
                    placeholder="State/Province"
                    type="text"
                    class="q-mb-lg"
                    bottom-slots
                    :error="fieldErrors('state').length > 0"
                    no-error-icon
                    style="margin-right: 18px;max-width: 47%;"
                    bg-color="white"
                  >
                    <template v-slot:error>
                      <div class="text-pink">
                        {{
                          fieldErrors("state").length > 0
                            ? fieldErrors("state")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                </div>
                <div class="infocardhalfflexdiv">
                  <q-input
                    v-model="country"
                    @input="$v.country.$touch()"
                    @blur="$v.country.$touch()"
                    outlined
                    placeholder="Country"
                    type="text"
                    class="q-mb-lg q-ml-sm flex-1"
                    bottom-slots
                    :error="fieldErrors('country').length > 0"
                    no-error-icon
                    input-class="caps-input"
                    bg-color="white"
                  >
                    <template v-slot:error>
                      <div class="text-pink">
                        {{
                          fieldErrors("country").length > 0
                            ? fieldErrors("country")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                </div>
              </div>
              <p v-show="invalidCodeMessage" class="mt-4 pink--text">
                {{ invalidCodeMessage }}
              </p>
              <q-btn type="submit" class="invisible" v-if="!isDisabled" />
              <q-btn
                color="teal-14"
                label="Done"
                class="q-mb-md q-py-sm"
                style="min-width:230px"
                @click="submit()"
                :loading="loader"
                :disabled="isDisabled"
              />
            </form>
          </div>
          <div class="vabotu-dialog-gradient overflow-hidden white--text">
            <div class="flex-x align-center q-mb-xl">
              <div>
                <div class="ar-white-color pa-px-30" v-if="isAdmin">
                  <span class="an-32 regular-text"
                    >The company was deactivated,</span
                  >
                  <span class="an-32 bold-text"
                    >add a card to continue using Heycollab</span
                  >
                  <br />
                  <span class="an-20 regular-text">
                    Your card will be charged US ${{ myPrice }}/month for
                    unlimited users. You may cancel your subscription at any
                    time.
                  </span>
                </div>
                <div class="ar-white-color pa-px-30" v-else>
                  <span class="an-32 regular-text"
                    >The company is currently deactivated.</span
                  >
                  <br />
                  <span class="an-20 regular-text"
                    >Contact the company owner to re-instate the company.</span
                  >
                </div>
                <div class="card border-radius6 bg-white q-pa-sm">
                  <div class="card__text">
                    <div
                      class="an-32 medium-text center-align pa-px-20 border-bottom black--text"
                    >
                      <span>Plan Includes:</span>
                    </div>
                    <hr class="sidebar" />
                    <div
                      class="q-pt-lg relative-position q-px-sm regular-text ar-grey-color"
                    >
                      <div class="flex-x">
                        <div style="padding-right:100px">
                          <p class="an-16 ar-black-color">Team Messaging</p>
                          <p class="an-16 blue-grey--text">
                            Unlimited searchable message archives & search for
                            relevant people, workspaces and files
                          </p>
                          <p class="an-16 blue-grey--text">
                            Multi-workspace and Single-workspace Guest Access
                          </p>
                          <p class="an-16 blue-grey--text">
                            Mandatory Two-factor authentication
                          </p>
                          <p class="an-16 blue-grey--text">
                            Private and Public Workspaces
                          </p>
                          <p class="an-16 blue-grey--text">
                            Compliance Exports of all messages
                          </p>
                          <p class="an-16 ar-black-color">Task Boards</p>
                          <p class="an-16 blue-grey--text">
                            Unlimited Task Boards, cards, tasks, checklists,
                            commenting, members & attachments
                          </p>
                          <p class="an-16 blue-grey--text">
                            Attach files up to 10MB/document or 20MB/video, up
                            to 100GB total
                          </p>
                          <p class="an-16 blue-grey--text">
                            Brand your boards with custom backgrounds and images
                          </p>
                          <p class="an-16 blue-grey--text">
                            Assign tasks and comments to members
                          </p>
                        </div>
                        <div>
                          <p class="an-16 ar-black-color">Collaboration</p>
                          <p class="an-16 blue-grey--text">
                            Unlimited Design Feedback
                          </p>
                          <p class="an-16 blue-grey--text">
                            Assign feedback to team members
                          </p>
                          <p class="an-16 blue-grey--text">
                            Easily Label Progress
                          </p>
                          <p class="an-16 ar-black-color">Other Benefits</p>
                          <p class="an-16 blue-grey--text">
                            100GB file storage per company (upgrafable to 200GB,
                            500GB or 1TB within the app)
                          </p>
                          <p class="an-16 blue-grey--text">
                            Mac and windows apps available. iOS and Android
                            coming in March 2019. Available on the Web and
                            supporting the following browsers: Google Chrome,
                            Safari and Firefox.
                          </p>
                          <p class="an-16 blue-grey--text">
                            128 bit SSL encryption
                          </p>
                          <p class="an-16 blue-grey--text">
                            99.99% Guaranteed Uptime SLA
                          </p>
                          <p class="an-16 blue-grey--text">Daily Backups</p>
                          <p class="an-16 blue-grey--text">
                            Priority Support (2 hours average response time)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import validationMixin from "@/mixins/validationMixin";
import { getUser } from "@/services/auth";
import errorHandler from "@/utils/ErrorHandler";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import StripeElement from "@/components/StripeElement";
import {
  CheckCouponCodeMutation,
  AddNewPayCardMutation,
  PayCardQuery,
} from "@/gql";

export default {
  name: "PaymentView",
  props: ["email", "first"],
  api: {
    company: {
      cacheKey: "CompanyQuery",
    },
  },
  mixins: [validationMixin],
  validations: {
    nameoncard: {
      required,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    street_one: {
      required,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    city: {
      required,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    zipcode: {
      required,
      minLength: minLength(5),
    },
    state: {
      required,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    country: {
      required,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
  },
  validationMessages: {
    nameoncard: {
      required: "message.validation.cardinfo.nameoncard.required",
      maxLength: "message.validation.cardinfo.nameoncard.maxLength",
      minLength: "message.validation.cardinfo.nameoncard.minLength",
    },
    street_one: {
      required: "message.validation.billingaddress.street_one.required",
      maxLength: "message.validation.billingaddress.street_one.maxLength",
      minLength: "message.validation.billingaddress.street_one.minLength",
    },
    city: {
      required: "message.validation.billingaddress.city.required",
      maxLength: "message.validation.billingaddress.city.maxLength",
      minLength: "message.validation.billingaddress.city.minLength",
    },
    zipcode: {
      required: "message.validation.billingaddress.zipcode.required",
      minLength: "message.validation.billingaddress.zipcode.minLength",
    },
    state: {
      required: "message.validation.billingaddress.state.required",
      maxLength: "message.validation.billingaddress.state.maxLength",
      minLength: "message.validation.billingaddress.state.minLength",
    },
    country: {
      required: "message.validation.billingaddress.country.required",
      maxLength: "message.validation.billingaddress.country.maxLength",
      minLength: "message.validation.billingaddress.country.minLength",
    },
  },
  components: {
    StripeElement,
  },
  created() {
    const activeCompanyObj = get(this.companies, this.activeCompany);
    this.setUser(activeCompanyObj);
    if (activeCompanyObj && activeCompanyObj.status) {
      this.$eventBus.$emit("checkCompanyStatus");
    }
  },
  data() {
    return {
      user: null,
      loader: null,
      nameoncard: null,
      cvv: null,
      street_one: null,
      street_two: null,
      city: null,
      country: null,
      state: null,
      zipcode: null,
      invalidCodeMessage: null,
      couponcode: "",
      isValidCouponcode: null,
      couponBtnColor: "greybtn",
      couponcodeMessage: null,
      validcard: false,
      cardErrors: "",
    };
  },
  methods: {
    async submit() {
      this.loader = true;
      // It must have NameOnCard
      const result = await this.$refs.card.createToken();
      if (result) {
        this.validcard = true;
        const variables = {
          token: {
            id: result.token.id,
            card_id: result.token.card.id,
            nameoncard: this.nameoncard,
            last4: result.token.card.last4,
          },
          address: {
            street1: this.street_one,
            street2: this.street_two,
            city: this.city,
            zipcode: this.zipcode,
            state: this.state,
            country: this.country,
          },
        };
        this.registerCardEventHandler(variables);
      }
    },
    async registerCardEventHandler(args) {
      this.loader = true;
      const variables = {
        ...args,
        first: this.user.first,
        email: this.user.email,
        coupon_code: this.couponcode,
      };
      try {
        const response = await this.$api.mutate({
          mutation: AddNewPayCardMutation,
          variables,
        });

        this.$api.query({
          query: PayCardQuery,
          cacheKey: "PayCardQuery",
        });

        if (
          response.data.addNewPayCard &&
          response.data.addNewPayCard.error === null
        ) {
          this.$store.dispatch("companyStatus", {
            isActive: true,
            subscriptionOver: false,
            graceHours: 0,
            hasPaycard: true,
          });
          this.$router.push(`/companies/${this.activeCompany}/1/messaging`);
        } else {
          this.invalidCodeMessage = response.data.addNewPayCard.error;
          this.loader = false;
        }
      } catch (error) {
        this.invalidCodeMessage = errorHandler.getErrorMessage(
          error,
          this,
          "message.validation.CardDeclined"
        );
        this.loader = false;
      }
    },
    async checkCouponCode() {
      if (this.couponcode !== "") {
        const variables = {
          coupon_code: this.couponcode,
        };
        try {
          const response = await this.$api.mutate({
            mutation: CheckCouponCodeMutation,
            variables,
          });
          if (response) {
            this.isValidCouponcode = true;
            this.couponcodeMessage = `Congrats! ${response.data.verifyCouponCode}`;
          }
        } catch (error) {
          this.isValidCouponcode = false;
          this.couponcodeMessage = this.$t(
            "message.validation.invalidCouponCode"
          );
          this.couponcode = "";
        }
      }
    },

    couponCodeDeleteEventHandler() {
      this.isValidCouponcode = null;
      this.couponcodeMessage = null;
    },
    async setUser(activeCompanyObj) {
      if (activeCompanyObj) {
        this.user = await getUser(activeCompanyObj.accessToken);
      }
    },
    stripeErrorHandler(message) {
      this.cardErrors = message;
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    myPrice() {
      if (this.company) {
        if (this.company.pricing_version === 1) {
          return 9;
        }
        return 12.99;
      }
      return 12.99;
    },
    isDisabled() {
      if (
        this.$v.$invalid ||
        this.cardErrors !== "" ||
        (this.couponcode !== "" && this.isValidCouponcode !== true)
      ) {
        return true;
      }
      return false;
    },
    isAdmin() {
      return this.user && this.user.userType === "Admin" ? true : false;
    },
  },
  watch: {
    couponcode(to) {
      if (to !== "") {
        this.couponBtnColor = "active";
      } else {
        this.couponBtnColor = "greybtn";
      }
    },
  },
};
</script>
